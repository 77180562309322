<template>
  <div class="flex flex-col gap-8">
    <div class="flex gap-4">
      <ic-tag />
      <h3 class="h3">All attributes</h3>
    </div>
    <div>
      <table aria-label="productAttributes">
        <tbody>
          <tr>
            <th scope="row">SKU</th>
            <th scope="row">{{ productAttributes.item_id ? productAttributes.item_id : '-' }}</th>
          </tr>
          <tr>
            <th scope="row">Google category</th>
            <th scope="row">{{ productAttributes.category ? productAttributes.category : '-' }}</th>
          </tr>
          <tr>
            <th scope="row">Brand</th>
            <th scope="row">{{ productAttributes.brand ? productAttributes.brand : '-' }}</th>
          </tr>
          <tr>
            <th scope="row">Price</th>
            <th scope="row">
              <span v-if="productAttributes.price">{{
                productAttributes.price | concat(' ' + productAttributes.currency)
              }}</span
              ><span v-else>-</span>
            </th>
          </tr>
          <tr>
            <th scope="row">Sale price</th>
            <th scope="row">
              <span v-if="productAttributes.sale_price">{{
                productAttributes.sale_price | concat(' ' + productAttributes.currency)
              }}</span>
              <span v-else>-</span>
            </th>
          </tr>
          <tr>
            <th scope="row">Availability</th>
            <th scope="row">{{ productAttributes.availability }}</th>
          </tr>
          <tr>
            <th scope="row">Link</th>
            <th scope="row">
              <bid-link
                v-if="productAttributes.url"
                :href-link="productAttributes.url"
                :router-link="false"
                target="_blank"
              >
                <template #text>
                  {{ productAttributes.url }}
                </template>
              </bid-link>
              <span v-else>-</span>
            </th>
          </tr>
          <tr>
            <th scope="row">Image link</th>
            <th scope="row">
              <bid-link
                v-if="productAttributes.image"
                :href-link="productAttributes.image"
                :router-link="false"
                target="_blank"
              >
                <template #text>
                  <img
                    v-lazy="productAttributes.image"
                    width="70"
                    :alt="productAttributes.image"
                  />
                </template>
              </bid-link>
              <span v-else>-</span>
            </th>
          </tr>
          <tr>
            <th scope="row">Condition</th>
            <th scope="row">{{ productAttributes.condition ? productAttributes.condition : '-' }}</th>
          </tr>
          <tr>
            <th scope="row">Additional image</th>
            <th
              scope="row"
              class="flex gap-3"
            >
              <span v-if="!productAttributes.additional_images.length">-</span>
              <bid-link
                v-else
                v-for="img in productAttributes.additional_images"
                :href-link="img"
                :router-link="false"
                target="_blank"
              >
                <template #text>
                  <img
                    v-lazy="img"
                    width="70"
                    :alt="img"
                  />
                </template>
              </bid-link>
            </th>
          </tr>
          <tr>
            <th scope="row">Product type</th>
            <th scope="row">{{ productAttributes.type ? productAttributes.type : '-' }}</th>
          </tr>
          <tr>
            <th scope="row">Shipping</th>
            <th
              scope="row"
              class="flex flex-col gap-3"
            >
              <span
                class="flex flex-col"
                v-if="productAttributes.shipping"
              >
                <span
                  v-if="hasManyShipping"
                  v-for="shipping in productAttributes.shipping"
                >
                  <span v-if="shipping.price"
                    >Price: {{ shipping.price | concat(' ' + productAttributes.currency) }}</span
                  >
                  <span v-else>Price: Free</span>
                </span>
                <span v-if="!hasManyShipping">
                  <span v-if="productAttributes.shipping.price"
                    >Price: {{ productAttributes.shipping.price | concat(' ' + productAttributes.currency) }}</span
                  >
                  <span v-else>Price: Free</span>
                </span>
              </span>
              <span v-else>-</span>
            </th>
          </tr>
          <tr>
            <th scope="row">Size</th>
            <th scope="row">{{ productAttributes.size ? productAttributes.size : '-' }}</th>
          </tr>
          <tr>
            <th scope="row">Colour</th>
            <th scope="row">{{ productAttributes.color ? productAttributes.color : '-' }}</th>
          </tr>
          <tr>
            <th scope="row">Gender</th>
            <th scope="row">{{ productAttributes.gender ? productAttributes.gender : '-' }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import IcTag from 'vue-material-design-icons/Tag'
import BidLink from '@/components/base/BidLink.vue'

export default {
  name: 'ProductAttributes',
  components: { BidLink, IcTag },
  props: {
    productAttributes: {
      type: Object,
      required: true,
    },
  },
  filters: {
    concat(value, string) {
      if (!value) return value
      return value.toString().concat(string)
    },
    prepend(value, string) {
      if (!value) return value
      return string + '' + value
    },
  },
  computed: {
    hasManyShipping() {
      return Array.isArray(this.productAttributes.shipping)
    },
  },
}
</script>

<style scoped lang="scss">
tr {
  th,
  td {
    text-align: left;
    padding-bottom: 24px;
    padding-right: 80px;
  }

  th:first-child {
    @apply text-lg font-bold;
  }

  th:last-child {
    @apply font-normal;
  }
}
</style>
