<template>
  <div class="flex flex-col gap-10">
    <div class="flex flex-col gap-6">
      <div class="flex items-center justify-between">
        <div class="flex flex-row justify-between w-full">
          <div class="flex gap-3 items-center">
            <ic-format-title :size="16" />
            <span class="font-semibold text-bb-dark-grey">Product title rewrite</span>
            <Transition
              mode="out-in"
              name="fade"
            >
              <div
                v-if="!editMode"
                key="no-editMode"
              ></div>
              <div
                v-if="editMode"
                key="editMode"
                class="text-bb-neon-purple flex flex-row gap-2"
              >
                <ic-wand
                  :size="16"
                  class="my-auto"
                ></ic-wand>
                <p class="p1 font-semibold my-auto">Suggested</p>
              </div>
            </Transition>
          </div>

          <Transition
            mode="out-in"
            name="fade"
          >
            <div
              key="no-editMode"
              v-if="!editMode"
              class="cursor-pointer hover:opacity-75 flex gap-2 text-bb-dull-blue underline"
              @click="toggleEditMode"
            >
              <ic-pencil
                :size="16"
                class="my-auto"
              ></ic-pencil>
              Edit title
            </div>
            <div
              key="editMode"
              v-if="editMode"
              class="flex flex-row gap-3"
            >
              <div
                class="flex gap-2 cursor-pointer hover:opacity-75"
                @click="saveProductTitle"
              >
                <ic-check
                  :size="16"
                  class="my-auto text-bb-dull-green"
                ></ic-check>
                <span class="text-bb-dull-blue underline"> Save </span>
              </div>
              <div
                class="flex gap-2 cursor-pointer hover:opacity-75"
                @click="toggleEditMode"
              >
                <ic-close
                  :size="16"
                  class="my-auto text-bb-dull-red"
                ></ic-close>
                <span class="text-bb-dull-blue underline"> Cancel </span>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <div class="flex flex-row items-center">
        <div class="w-full mr-3">
          <Transition
            mode="out-in"
            name="fade"
          >
            <div
              key="no-editMode"
              v-if="!editMode"
            >
              <h2 class="h2 text-bb-dark-grey">{{ productInfo.title ? productInfo.title : '-' }}</h2>
            </div>
            <div
              key="editMode"
              v-if="editMode"
              class="flex flex-col pb-4"
            >
              <span class="bg-bb-neon-purple text-white p4 p-1 rounded-t mr-auto">Editing</span>
              <div class="border border-bb-neon-purple flex flex-row">
                <input
                  :maxlength="150"
                  label="TEST"
                  :placeholder="productInfo.title ? productInfo.title : '-'"
                  v-model="editedTitle"
                  @keyup.enter="saveProductTitle"
                  class="h2 w-full mr-0 text-bb-dark-grey px-3 py-1 focus:outline-none"
                />
                <span
                  v-if="editMode"
                  class="text-bb-mid-grey text-sm my-auto mr-3"
                  >{{ editedTitle.length }}/150</span
                >
                <span
                  v-else
                  class="text-bb-mid-grey text-sm my-auto mr-3"
                  >{{ productInfo.title_score.length }}/150</span
                >
              </div>
            </div>
          </Transition>
        </div>
        <div class="flex">
          <Transition
            mode="out-in"
            name="fade"
          >
            <span
              v-if="!editMode"
              key="no-editMode"
              class="text-bb-mid-grey text-sm mr-3"
              >{{ productInfo.title_score.length }}/150</span
            >
            <span
              v-if="editMode"
              key="editMode"
            ></span>
          </Transition>
          <quality-badge
            :success="titleGrade ? titleGrade == 'Good' : productInfo.title_score.quality == 'Good'"
            :warn="titleGrade ? titleGrade == 'Ok' : productInfo.title_score.quality == 'Ok'"
            :error="titleGrade ? titleGrade == 'Poor' : productInfo.title_score.quality == 'Poor'"
            class="my-auto"
          >
            <template>
              {{ titleGrade ? titleGrade : productInfo.title_score.quality }}
            </template>
          </quality-badge>
        </div>
      </div>
      <Transition
        mode="out-in"
        name="fade"
      >
        <div
          v-if="!editMode"
          key="no-editMode"
        ></div>
        <div
          v-if="editMode"
          key="editMode"
          class="ml-0 mx-auto h5"
        >
          <div v-if="editedTitle.toLowerCase().includes(productInfo.brand.toLowerCase())">
            <div
              class="flex flex-row suggested-text gap-2 bg-bb-custom-green border border-bb-custom-green text-bb-neon-green rounded cursor-default"
            >
              <ic-check
                :size="16"
                class="my-auto"
              ></ic-check>
              <span>
                {{ productInfo.brand }}
              </span>
            </div>
          </div>
          <div
            v-else
            @click="appendSuggestedKeyword"
          >
            <div
              class="flex flex-row suggested-text gap-2 text-bb-neon-purple border border-bb-neon-purple rounded cursor-pointer"
            >
              <ic-plus
                :size="16"
                class="my-auto"
              ></ic-plus>
              <span>
                {{ productInfo.brand }}
              </span>
            </div>
          </div>
        </div>
      </Transition>
      <span class="italic text-bb-dark-grey">Tip: Google recommends between 70-150 characters.</span>
    </div>
    <div class="flex flex-col gap-6">
      <div class="flex gap-3 items-center">
        <ic-groups :size="16" />
        <span class="font-semibold text-bb-dark-grey">Google category</span>
      </div>
      <div class="flex gap-3 items-center">
        <div class="flex flex-1">
          <h2 class="h2 text-bb-dark-grey">{{ productInfo.category ? productInfo.category : '-' }}</h2>
        </div>
        <div class="flex gap-3">
          <span class="text-bb-mid-grey text-sm">{{ productInfo.category_score.depth }}/5 levels set</span>
          <quality-badge
            :success="productInfo.category_score.quality === 'Great'"
            :warn="productInfo.category_score.quality === 'Good'"
            :error="productInfo.category_score.quality === 'Poor'"
          >
            <template>
              {{ productInfo.category_score.quality }}
            </template>
          </quality-badge>
        </div>
      </div>
      <span class="italic text-bb-dark-grey"
        >Tip: Bidbrain recommends using at least 3-5 levels depending on segment</span
      >
    </div>
    <div class="flex flex-col gap-6">
      <div class="flex gap-3 items-center">
        <ic-tag :size="16" />
        <span class="font-semibold text-bb-dark-grey">GTIN tag</span>
      </div>
      <div class="flex gap-3 items-center">
        <div class="flex flex-1 justify-between items-center">
          <h2 class="h2 text-bb-dark-grey">{{ productInfo.gtin ? productInfo.gtin : '-' }}</h2>
          <quality-badge
            :error="productInfo.gtin_score === 'Bad'"
            :success="productInfo.gtin_score === 'Great'"
          >
            <template>
              {{ productInfo.gtin_score }}
            </template>
          </quality-badge>
        </div>
      </div>
      <div>
        <the-button
          secondary
          disabled
          v-if="!productInfo.gtin"
        >
          <template> GTIN not set </template>
        </the-button>
      </div>
      <span class="italic text-bb-dark-grey"
        >Tip: Insert GTIN if possible, products without GTIN should set the identifier_exists attribute to false.</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IcPencil from 'vue-material-design-icons/Pencil.vue'
import IcFormatTitle from 'vue-material-design-icons/FormatTitle.vue'
import IcGroups from 'vue-material-design-icons/Shape'
import IcTag from 'vue-material-design-icons/Tag'
import IcCheck from 'vue-material-design-icons/Check'
import IcClose from 'vue-material-design-icons/Close'
import IcPlus from 'vue-material-design-icons/Plus'
import IcWand from 'vue-material-design-icons/AutoFix'
import QualityBadge from '@/components/shared/QualityBadge.vue'
import InputText from '@/components/input/InputText'
import Toast from '@/components/shared/Toast'

export default {
  name: 'ProductInfo',
  components: {
    QualityBadge,
    IcFormatTitle,
    IcPencil,
    IcGroups,
    IcTag,
    IcClose,
    IcCheck,
    InputText,
    IcWand,
    IcPlus,
    Toast,
  },
  props: {
    productInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      loading: false,
      editedTitle: null,
    }
  },

  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode
      if (!this.editMode) {
        this.editedTitle = null
      }
      if (this.editMode) {
        this.editedTitle = this.productInfo.title
      }
    },
    async saveProductTitle() {
      try {
        await this.$http.post(`/store/${this.store.id}/product/${this.productInfo.id}/title-rewrite`, {
          title: this.editedTitle,
        })
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Product title has been updated!',
            type: 'success',
          },
        })
        this.editMode = false
        this.productInfo.title = this.editedTitle
        this.productInfo.title_score.length = this.editedTitle.length
      } catch (err) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `${err}`,
            type: 'error',
          },
        })
      }
    },
    appendSuggestedKeyword() {
      if (this.editedTitle.length <= 150 - this.productInfo.brand.length - 1) {
        this.editedTitle = `${this.editedTitle} ${this.productInfo.brand}`
      }
    },
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
    }),
    titleGrade() {
      if (this.editedTitle) {
        if (this.editedTitle.length < 60 || this.editedTitle.length > 150) {
          return 'Poor'
        } else if (this.editedTitle.length >= 60 && this.editedTitle.length < 100) {
          return 'Ok'
        } else if (this.editedTitle.length >= 100 && this.editedTitle.length <= 150) {
          return 'Good'
        } else {
          return 'Invalid Input'
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.word-exists {
  background-color: #25cf7e;
}

.suggested-text {
  padding-left: 14px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>
