<template>
  <page>
    <page-section bottom-border>
      <bid-link
        class="flex-row-reverse gap-3"
        href-link="products:overview"
        :router-link="true"
      >
        <template #icon>
          <ic-chevron-left />
        </template>
        <template #text> Back to products </template>
      </bid-link>
    </page-section>
    <page-section v-if="loading">
      <loader-ellipsis class="text-bb-neon-purple transform" />
      Load Product
    </page-section>
    <div v-else>
      <div v-if="product">
        <page-section bottom-border>
          <product-info :product-info="this.product" />
        </page-section>
        <page-section>
          <product-attributes :product-attributes="this.product" />
        </page-section>
      </div>
      <page-section v-else> Not enough data for this product </page-section>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import IcGroups from 'vue-material-design-icons/Shape.vue'
import BidLink from '@/components/base/BidLink.vue'
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcFormatTitle from 'vue-material-design-icons/FormatTitle'
import IcPencil from 'vue-material-design-icons/Pencil'
import QualityBadge from '@/components/shared/QualityBadge.vue'
import ProductInfo from '@/components/product/details/ProductInfo.vue'
import ProductAttributes from '@/components/product/details/ProductAttributes.vue'
import { mapGetters } from 'vuex'
import LoaderEllipsis from '@/components/loader/EllipsisLoader.vue'

export default {
  name: 'ProductDetails',
  components: {
    LoaderEllipsis,
    ProductAttributes,
    ProductInfo,
    QualityBadge,
    BidLink,
    IcGroups,
    PageSection,
    Page,
    IcChevronLeft,
    IcFormatTitle,
    IcPencil,
  },
  data() {
    return {
      product: null,
      loading: false,
    }
  },
  watch: {
    storeId() {
      this.$router.push({ name: 'products:overview' })
    },
  },
  computed: {
    ...mapGetters({
      storeId: 'store/selectedStoreId',
    }),
  },
  async mounted() {
    const productId = this.$route.params.id
    this.loading = true
    try {
      const res = await axios.get(`product/${productId}`)
      this.product = res.data.data
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  },
}
</script>

<style scoped></style>
