<template>
  <div
    class="quality-badge max-w-xxs"
    :class="{ success, warn, error }"
  >
    <p class="p5 my-auto px-1 mx-auto">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'quality-badge',
  props: {
    success: Boolean,
    warn: Boolean,
    error: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.quality-badge {
  @apply bg-bb-cool-grey rounded;

  &.success {
    @apply bg-bb-custom-green text-bb-dull-green;
  }

  &.warn {
    @apply bg-bb-custom-orange text-bb-dull-orange;
  }

  &.error {
    @apply bg-bb-custom-red text-bb-dull-red;
  }
}
</style>
